// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectionThree_section-three__gj7PO {
  padding: 20px;
  background-color: #f4f4f4;
}
.SectionThree_section-three__gj7PO h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}
.SectionThree_section-three__gj7PO p {
  color: #666;
  font-size: 16px;
}
.SectionThree_section-three__gj7PO .SectionThree_contact-info__DtoKY {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.SectionThree_section-three__gj7PO .SectionThree_contact-info__DtoKY p {
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/SectionThree.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,mBAAA;AACJ;AAEE;EACE,WAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;AADJ;AAGI;EACE,aAAA;AADN","sourcesContent":[".section-three {\r\n  padding: 20px;\r\n  background-color: #f4f4f4;\r\n\r\n  h2 {\r\n    color: #333;\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  p {\r\n    color: #666;\r\n    font-size: 16px;\r\n  }\r\n\r\n  .contact-info {\r\n    margin-top: 20px;\r\n    padding: 10px;\r\n    background-color: #fff;\r\n    border: 1px solid #ddd;\r\n\r\n    p {\r\n      margin: 5px 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-three": `SectionThree_section-three__gj7PO`,
	"contact-info": `SectionThree_contact-info__DtoKY`
};
export default ___CSS_LOADER_EXPORT___;
