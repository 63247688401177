import React from 'react';
import SectionOne from '../components/Section1';
import SectionTwo from '../components/Section2';
import SectionThree from '../components/Section3';

const LandingPage: React.FC = () => {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </div>
  );
};

export default LandingPage;
