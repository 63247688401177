import React from 'react';

const SectionTwo: React.FC = () => {
  return (
    <section>
      <h2>Features</h2>
      <p>This is the second section of the landing page, highlighting the features.</p>
    </section>
  );
};

export default SectionTwo;
