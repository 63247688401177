import React from 'react';
import styles from './SectionThree.module.scss';

const SectionThree: React.FC = () => {
  return (
    <section className={styles['section-three']}>
      <h2>Contact Us</h2>
      <p>This is the third section of the landing page, providing contact information.</p>
      <div className={styles['contact-info']}>
        <p>Email: contact@ruirarchive.com</p>
        <p>Phone: (123) 456-7890</p>
      </div>
    </section>
  );
};

export default SectionThree;
